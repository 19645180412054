import dmaLogo from './images/dma-logo.png'
import skizaImg1 from './images/skiza1.png'
import skizaImg2 from './images/skiza2.jpeg'
import personOnPhone from './images/person-onphone.png'


// Local brouser routes
const siteUrls = {
    home: '/',
    portal: '/portal',
    listSkizaTunes: '/portal/',
    listBusinessTunes: '/portal/business',
    listAccounts: '/portal/accounts',
    listSkizaRequests: '/portal/skiza-requests',
    skizaRequestDetail: '/portal/skiza-request-detail',
    skizaTunesPage: '/skiza-tunes',
    skizaBiasharaPage: '/skiza-biashara'
}

const server = 'https://skizaapi.digitalaggregators.com'
 //const server = 'http://127.0.0.1:5000'


// API endpoint urls
const urls = {
    login: `${server}/login`,
    signup: `${server}/users/signup`,
    listSkizaCategories: `${server}/skiza-tunes/categories`,
    listSkizaTunes: `${server}/skiza-tunes/tunes`,
    skizaTuneDetail: tuneId =>`${server}/skiza-tunes/tunes/${tuneId}`,
    editSkizaAudio: tuneId =>`${server}/skiza-tunes/tunes/${tuneId}/edit-audio`,
    featureSkizaTune: tuneId =>`${server}/skiza-tunes/tunes/${tuneId}/feature`,
    unFeatureSkizaTune: tuneId =>`${server}/skiza-tunes/tunes/${tuneId}/un_feature`,
    listUserAccounts: `${server}/users`,
    makeUserAdmin: userId => `${server}/users/${userId}/make_admin`,
    demoteAdmin: userId => `${server}/users/${userId}/demote`,
    listTuneRequests: `${server}/tune-requests`,
    processTuneRequest: requestId => `${server}/tune-requests/${requestId}/process`,
    audioPath: filePath => `https://skiza.digitalaggregators.com/audio-files/${filePath}`,
    queueSubscriptions: `${server}/queued_subscriptions`
}

export {
    dmaLogo,
    skizaImg1,
    skizaImg2,
    personOnPhone,
    siteUrls,
    urls
}
