import { Box, Button, Container, Grid, styled, TextField, Typography, Modal} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SelectInput from "../components/SelectInput";
import SkizaItem from "../components/SkizaItem";
import { urls } from "../constants";
import { getData } from "../httpUtils";
import { setSkizaCategories, setSkizaTunes } from "../redux/actions";

const SearchBtn = styled(Button)(({theme})=>({
    marginTop: '4vh',
    color: 'white',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: 0
    }
}))

const SkizaTunesPage = () => {
    const dispatch = useDispatch()
    const skizaTunes = useSelector(state=>state.skizaTunes)
    const skizaTunesToDisplay = skizaTunes? skizaTunes.filter(tune=>tune.category_name != 'Sample Business Tunes') : []
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const initialQ = params.get('search') || ''

    const [query, setQuery] = useState(initialQ)


    const categories = useSelector(state=>state.skizaCategories.filter(cat=>cat.name != 'Sample Business Tunes')) || []
    const [state, setState] = useState({category_id: ''})
    const categoryList = [{id: '', name:'All'}, ...categories.map(cat=>({id: cat.id, name:cat.name}))]

   

  

    const fetchSkizaTunes = () =>{
        getData(`${urls.listSkizaTunes}?search=${query}&category_id=${state.category_id}`).then(resp=>{
            console.log('Fetch tunes: ', resp)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                console.log('Fetch tunes tunes: ', responseData)
                dispatch(setSkizaTunes(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    const handleSearch = () => {
        fetchSkizaTunes()
    }

    useEffect(() => {
        //fetch skiza categories
        getData(urls.listSkizaCategories).then(resp=>{
            console.log('fetch categories: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaCategories(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })

        fetchSkizaTunes()
    }, []);

    return (
        <Box sx={{my: 5, mx: '3%', display: 'flex', flexDirection:'column', minHeight:'60vh'}}>

            <Typography variant="h3" textAlign='center' sx={{fontWeight: 'bold'}}>
                Skiza Tunes
            </Typography>
            <Typography textAlign='center'>
                Choose a skiza tune and entertain your callers today
            </Typography>
            <Container>         


                <Grid container gap={4} justifyContent='center'>
                    <Grid item xs={12} md={4}>
                        <TextField id="outlined-basic" label="Skiza Code or name" variant="filled" fullWidth sx={{
                            mt: 2,
                            mb: 0
                        }} onChange={e =>setQuery(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput
                            value ={state.category_id}
                            id='category_id'
                            label='Filter by Category'
                            onChange={e=>setState({...state, category_id: e.target.value})}
                            options={categoryList}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SearchBtn variant="contained" onClick={handleSearch}>
                            Search tunes
                        </SearchBtn>
                    </Grid>
                </Grid>
            </Container>
            <Grid container justifyContent='space-evenly' flexGrow={1}>
                {skizaTunesToDisplay.map(tune=><SkizaItem key={tune.id} tune={tune} />)}
            </Grid>
       
        </Box>
        
    );
}
 
export default SkizaTunesPage;
