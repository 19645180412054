import { Box, Checkbox, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FlashMessage from "../components/FlashMessage";
import { getData, putData } from "../httpUtils";
import { setUsers } from "../redux/actions";
import { urls } from "../constants";
import Pagination from "../components/Pagination";

const ListUserAccounts = () => {
    const dispatch = useDispatch()
    const users = useSelector(state=>state.users)
    const accessToken = useSelector(state=>state.accessToken)

    // Flash alerts
    const [flashOpen, setFlashOpen] = useState(false)
    const closeFlash = () => setFlashOpen(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('success')
    const flash = (message, type='success') =>{
        setAlertMessage(message)
        setAlertType(type)
        setFlashOpen(true)
    }

    // use for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination] = useState({})
    const handleSetPagination = paginationObj => {
        setPagination({...paginationObj, items: null})
    }

    const makeUserAdmin = userId => {
        putData(urls.makeUserAdmin(userId), {}, accessToken).then(resp=>{
            console.log('make admin: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==201){
                fetchUsers()
            } else{
                flash('Could not update admin status', 'error')
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    const demoteAdmin = userId => {
        putData(urls.demoteAdmin(userId), {}, accessToken).then(resp=>{
            console.log('demote admin: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==201){
                fetchUsers()
            } else{
                flash('Could not update admin status', 'error')
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    const handleChangeIsAdmin = user =>{
        if(user.is_admin){
            demoteAdmin( user.id)
        } else {
            makeUserAdmin(user.id)
        }
    }

    const fetchUsers = () =>{
        getData(`${urls.listUserAccounts}?page=${currentPage}`, accessToken).then(resp=>{
            console.log('fetch users: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setUsers(responseData.data.items))
                handleSetPagination(responseData.data)
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    useEffect(() => {
        fetchUsers()

    }, [currentPage]);
    return (
        <>
        {flashOpen && <FlashMessage closeFlash={closeFlash} message={alertMessage} type={alertType}/>}

        <Typography variant='h5' sx={{
            ml: 1,
            fontWeight: 'bold',
            mt: 1,
            mb: 2
        }}
        >
            User Accounts
        </Typography>
    
        <Divider/>
        <TableContainer component={Box}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Date Joined</TableCell>
                    <TableCell>Is Admin</TableCell>
                    <TableCell>Super Admin</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((user) => (
                <TableRow
                    key={user.id}
                >
                    <TableCell component="td" scope="row">{user.full_name} </TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.date_joined}</TableCell>
                    <TableCell>
                        <Checkbox checked= {user.is_admin} onChange={() =>handleChangeIsAdmin(user)}/>
                    </TableCell>
                    <TableCell>
                        <Checkbox disabled checked={user.is_super_admin}/>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <Pagination pagination={pagination} setCurrentPage={setCurrentPage}/>
        </>
    );
}
 
export default ListUserAccounts;
