import { payments} from '../data'

const initialState = {
    currentUser: null,
    accessToken: null,
    skizaCategories: [],
    skizaTunes: [],
    skizaRequests: [],
    payments: payments,
    users: []
}

const reducer = (state=initialState, action) =>{
    switch(action.type){
        case 'SET_ACCESS_TOKEN':
            return {...state, accessToken: action.payload}
        
        case 'SET_CURRENT_USER':
            return {...state, currentUser: action.payload}

        case 'LOGOUT':
            return initialState

        case 'SET_SKIZA_TUNES':
            return {...state, skizaTunes: action.payload}

        case 'SET_SKIZA_CATEGORIES':
            return {...state, skizaCategories: action.payload}
        
        case 'SET_USERS':
            return {...state, users: action.payload}
        
        case 'SET_SKIZA_REQUESTS':
            return {...state, skizaRequests: action.payload}
        
        case 'SET_CURRENT_REQUEST_ID':
            return {...state, currentRequestId: action.payload}

        default:
            return state
    }
}

export default reducer
