import { Box, Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";

import {siteUrls, urls} from '../constants'
import { getData } from "../httpUtils";
import { setCurrentRequestId, setSkizaRequests } from "../redux/actions";

const ListSkizaRequests = () => {
    const dispatch = useDispatch()
    const accessToken = useSelector(state=>state.accessToken)
    const skizaRequests = useSelector(state=>state.skizaRequests)

    // use for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination] = useState({})
    const handleSetPagination = paginationObj => {
        setPagination({...paginationObj, items: null})
    }

    const fetchSkizaRequests = () =>{
        getData(`${urls.listTuneRequests}?page=${currentPage}`, accessToken).then(resp=>{
            console.log('Fetch tune requests: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaRequests(responseData.data.items))
                handleSetPagination(responseData.data)
            }
        }).catch(e=>{
            console.log(e)
        })        
    }

    useEffect(() => {
        fetchSkizaRequests()
    }, [currentPage]);

    return (
        <>
        <Typography variant='h5' sx={{
            ml: 1,
            fontWeight: 'bold',
            mt: 1,
            mb: 2
        }}
        >
            Skiza tune Requests
        </Typography>
    
        <Divider/>
        <TableContainer component={Box}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Business</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Payment</TableCell>
                    <TableCell>Is processed</TableCell>
                    <TableCell># Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(skizaRequests && skizaRequests.length)?
                <>
                {skizaRequests.map((request) => (
                <TableRow
                    key={request.id}
                >
                    <TableCell component="td" scope="row">{request.id} </TableCell>
                    <TableCell>{request.customer_name}</TableCell>
                    <TableCell>{request.business_name}</TableCell>
                    <TableCell>{request.customer_phone}</TableCell>
                    <TableCell>{request.payment_status}</TableCell>
                    <TableCell>
                        {request.is_processed? 'Processed': 'Pending'}
                    </TableCell>
                    <TableCell>
                        <Link to={siteUrls.skizaRequestDetail} style={{textDecoration: 'none'}}>
                            <Button variant="contained"
                                onClick={e=>dispatch(setCurrentRequestId(request.id))}
                                sx={{color:'white'}}
                            >
                                View
                            </Button>
                        </Link>
                    </TableCell>
                </TableRow>
                ))}
                </> :
                <TableCell colSpan={7} align='center'> No requests yet</TableCell>
                }

            </TableBody>
            </Table>
        </TableContainer>
        <Pagination pagination={pagination} setCurrentPage={setCurrentPage}/>
        </>
    );
}
 
export default ListSkizaRequests;
