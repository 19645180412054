import { Paper, Typography, Button, styled , Modal, Grid, Box} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import { Download } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { postData } from "../httpUtils";


import {CenteredGrid} from '../styledComponents';
import { urls } from "../constants";
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import TextInput from "./TextInput";
const LinkItem = styled(Link)({
  color: 'black'
})

const GetNowLink = styled('a')({
    textDecoration: 'none'
})

const SkizaItem = ({tune}) => {
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
      skiza_tune: tune.code,      
      msisdn: '',
      price: tune.skiza_price
     
  })
  const [error, setError] = useState()
  const [errorFields, setErrorFields] = useState()


    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    const handleSubmit =e => {
      var records ={
        skiza_tune: tune.code,      
        msisdn: state.msisdn,
        price: tune.skiza_price,
        skiza_tune_id:tune.id,
        correlator_id :uuidv4(),

      }
      console.log('state is',state)
      e.preventDefault()
        setOpen(false);
        Swal.fire({
            title: 'Please confirm you wish to set '+ tune.name+ ' as your Skiza for @Ksh'+tune.skiza_price+' daily',
            showDenyButton: true,          
            confirmButtonText: 'Confirm',
            denyButtonText: `Cancel`,
          }).then((result) => {           
            if (result.isConfirmed) {   
              
            
                postData(urls.queueSubscriptions, records).then(resp=>{
                    console.log('res is.....',resp.status)
                    return resp.json()
                }).then(data=>{
                    if(data.response_code ==400){
                        setErrorFields(data.data.error_fields)
                    } else if (data.response_code == 201){
                      Swal.fire('You have Successfully downloaded your SKIZA Tune.The tune will be renewed @Ksh'+tune.skiza_price+' Daily', '', 'success') 
                    }
                }).catch(e=>{
                    setError("Could't send your request")
                    console.log(e)
                })
            
                 
                            
            

            } else if (result.isDenied) {
              Swal.fire('Thank you for using our services', '', 'info')
            }
          })
    }
    return (
        <CenteredGrid item xs={12} sm={6} md={4} >
            <Paper sx={{
                backgroundColor: 'lightGrey.main',
                height: 170,
                width: '90%',
                mt: 4,
                borderTopLeftRadius: 20,
                borderBottomRightRadius: 20,
                px: 1,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'

            }}>
                <Typography variant="body2" textAlign='center' sx={{fontWeight: 'bold'}} gutterBottom>
                    {tune.name}
                </Typography>
                <Typography textAlign='center'>
                    <Typography variant="overline">
                        SKIZA CODE: 
                        <Typography variant="overline" fontWeight='bold'>{tune.code}</Typography>
                    </Typography>
                </Typography>

                <audio controls controlsList="nodownload">
                    <source src={urls.audioPath(tune.file_path)} type="audio/mpeg"></source>
                </audio>
{/* 
                <GetNowLink href={`sms:811?&body=Skiza ${tune.code}`}> */}
                    <Button size='small' variant="contained" endIcon={<Download/>}
                        sx={{color: 'white'}} onClick={handleOpen}
                    >
                        Get it now
                    </Button>
                {/* </GetNowLink> */}

            </Paper>
            <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
         <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
              width: '80%',
              maxWidth: 400,
            }}
        >
          <Button
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </Button>
          <Typography variant="h6" component="h2" textAlign='center' sx={{ mb: 2, marginTop: '50px' }}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'> */}
            Download Skiza Tune-{tune.name}
            </Typography>

            {error && <Typography color='error' textAlign='center'>{error}</Typography>}
            <Grid container>
                <Grid item xs={12} paddingX={1}>
                    <TextInput
                        value={`Skiza Code ${tune.code}`}           
                        id='skiza_tune'                       
                        readOnly= {{readOnly: true}}
                       
                        
                       
                    />
                   
                     <TextInput
                        value={`Skiza Price ${tune.skiza_price}`}                        
                        id='price'                       
                        readOnly= {{readOnly: true}}
                       
                       
                    />
                    <TextInput
                        value={state.msisdn}
                        label="Enter Phone Number"
                        id='msisdn'
                        onChange={(e)=>setState({...state, msisdn: e.target.value})}
                        readOnly= {{readOnly: false}}
                       
                        
                    />
                </Grid>
              
            </Grid>
            <Button variant="contained" fullWidth type='submit' sx={{mt: 3, mb: 2, color: 'white'}}>
                Submit
            </Button>
           
        </Box>
       
      </Modal>
        </CenteredGrid>
    );
}

export default SkizaItem;
