import { Grid, Box, styled, Typography, Button } from "@mui/material";

import { CenteredGrid } from "../styledComponents";
import {siteUrls, skizaImg2} from '../constants'
import { Link } from "react-router-dom";

const BannerGridImg = styled(Grid)(({theme})=>({
    backgroundImage: `url(${skizaImg2})`,
    backgroundSize: 'cover',
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block'
    }
}))

const CallToActionGrid = styled(Grid)(({theme})=>({
    color: theme.palette.darkGrey.main,
    [theme.breakpoints.down('md')]: {
        backgroundImage: `url(${skizaImg2})`,
        backgroundSize: 'cover',
        color: 'white'
    }
}))

const LinkItem = styled(Link)({
    textDecoration: 'none'
})

const Banner = () => {
    return (
        <Grid container>
            <BannerGridImg item md={6} xs={12}>
                <Box className="intro-section"></Box>
            </BannerGridImg>
            <CallToActionGrid item xs={12} md={6}
            sx={{
                backgroundColor: 'lightGrey.main'
            }}
            >
                <Box className="call-to-action" sx={{paddingTop: 15}}>
                    <Typography className="call-to-action-header" variant="h2" component='h1' textAlign='center' sx={{
                        fontWeight: 'bold'
                    }}>
                        All Your favourite <span className="pacifico">skiza</span> tunes
                    </Typography>
                    <Box className="call-to-action-btns" sx={{mt:12}}>
                        <Grid container>
                            <CenteredGrid item xs={12} md={6}>
                                <LinkItem to={siteUrls.skizaTunesPage}>
                                    <Button variant="contained" sx={{my: 2, color: 'white'}}>
                                        Explore Skiza Tunes
                                    </Button>
                                </LinkItem>
                            </CenteredGrid>
                            <CenteredGrid item xs={12} md={6}>
                                <LinkItem to={siteUrls.skizaBiasharaPage}>
                                    <Button variant="contained" sx={{my: 2, color: 'white'}}>
                                        Customize Skiza for your Business
                                    </Button>
                                </LinkItem>
                            </CenteredGrid>
                        </Grid>
                    </Box>
                </Box>
            </CallToActionGrid>
        </Grid>
    );
}
 
export default Banner;
