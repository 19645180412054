import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Toolbar, Box, Typography, Container, styled, Menu, MenuItem, Divider} from '@mui/material'

import { dmaLogo, siteUrls } from '../constants';
import { Person } from '@mui/icons-material';

const NavLink = styled(Link)({
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'white'
})

const MenuLink = styled(Link)(({theme})=>({
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main
}))

const LogoImg = styled('img')({
    width: '90px',
    height: 'auto',
    marginTop: 10
})

const Nav = () => {
    const currentUser = useSelector(state=>state.currentUser)

    // pop up menu controls
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
        <AppBar position='sticky'>
            <Toolbar sx={{bgcolor: 'black'}}>
                <Container sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant='h6' flexGrow={2}>
                        <NavLink to={siteUrls.home}>
                            <LogoImg src={dmaLogo}/>
                        </NavLink>
                    </Typography>
                    <Box flexGrow={1} alignContent='space-between' sx={{
                        display: {
                            xs: 'none',
                            sm: 'flex'
                        },
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        alignItems: 'baseline',
                        
                    }}>
                        <NavLink to={siteUrls.home}>
                            Home
                        </NavLink>
                        <NavLink to={siteUrls.skizaTunesPage}>
                            Skiza Tunes
                        </NavLink>
                        <NavLink to={siteUrls.skizaBiasharaPage}>
                            Skiza Biashara
                        </NavLink>
                        {currentUser && (
                        <NavLink to={(currentUser.is_admin || currentUser.is_super_admin)? siteUrls.portal: siteUrls.home}>
                            <Person/> Go to Portal
                        </NavLink>)}
                    </Box>
                    <Box sx={{
                        display: {
                            xs: 'flex',
                            sm: 'none'
                        },
                    }}>
                        <Box color='white'>
                            <MenuIcon
                                aria-controls={menuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? 'true' : undefined}
                                onClick={handleClick}
                            />
                        </Box>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                        }}
                        >
                            <MenuItem onClick={handleMenuClose}>
                                <MenuLink to={siteUrls.home}>
                                    Home
                                </MenuLink>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleMenuClose}>
                                <MenuLink to={siteUrls.skizaTunesPage}>
                                    Skiza Tunes
                                </MenuLink>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleMenuClose}>
                                <MenuLink to={siteUrls.skizaBiasharaPage}>
                                    Skiza Biashara
                                </MenuLink>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>

        </>
    );
}
 
export default Nav;