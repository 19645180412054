import { Check } from "@mui/icons-material";
import { Box, Button, Divider, Grid, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { siteUrls, urls } from "../constants";
import { getData, putData } from "../httpUtils";
import { setSkizaRequests } from "../redux/actions";

const RequestHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: '3vh',
    paddingTop: '2vh'
})

const SkizaRequestDetail = () => {
    const currentRequestId = useSelector(state=>state.currentRequestId)
    const currentRequest = useSelector(state=>state.skizaRequests.find(request=>request.id==currentRequestId)) || {}
    const accessToken = useSelector(state=>state.accessToken)

    const dispatch = useDispatch()

    const fetchSkizaRequests = () =>{
        getData(urls.listTuneRequests, accessToken).then(resp=>{
            console.log('Fetch tune requests: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaRequests(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    const handleProcessRequest = () => {
        putData(urls.processTuneRequest(currentRequest.id), {}, accessToken).then(resp=>{
            console.log('Process request: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==201){
                fetchSkizaRequests()
            } else {
                alert('Could not update record')
            }
        }).catch(e=>{
            console.log(e)
            alert('Could not update record')
        })
    }
    return (
        <>
        <RequestHeader>
            <Typography variant='h5' sx={{
                ml: 1,
                fontWeight: 'bold'
            }}
            >
                Skiza tune Request #{currentRequest.id} from {currentRequest.customer_name}
            </Typography>
            <Link to={siteUrls.listSkizaRequests} style={{textDecoration: 'none'}}>
                <Button variant='contained' size="small" sx={{color:'white'}}>
                    View All Requests
                </Button>
            </Link>
        </RequestHeader>
        <Divider/>


        <Grid container>
            <Grid item xs={12} md={6}>
                <TableContainer component={Box}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell variant='head'>Request No</TableCell>
                                <TableCell>{currentRequest.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Customer Name</TableCell>
                                <TableCell>{currentRequest.customer_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Business Name</TableCell>
                                <TableCell>{currentRequest.business_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Preferred Voice</TableCell>
                                <TableCell>{currentRequest.preferred_voice}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Is Processed</TableCell>
                                <TableCell>
                                    {currentRequest.is_processed? "Processed": 'Pending'}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
            <TableContainer component={Box}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell variant='head'>Location</TableCell>
                                <TableCell >{currentRequest.business_location}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Contact Email</TableCell>
                                <TableCell>{currentRequest.customer_email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Contact Phone</TableCell>
                                <TableCell>{currentRequest.customer_phone}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Payment Status</TableCell>
                                <TableCell>{currentRequest.payment_status}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>

        {currentRequest.is_processed? null:
        <Button variant='contained' sx={{mt: 3, color:'white'}} startIcon={<Check/>} onClick={handleProcessRequest}>
            Mark as Processed
        </Button>
        }
        <Box paddingY={3}>
            <Typography variant="h6" gutterBottom>Business description</Typography>
            <Typography>{currentRequest.business_description}</Typography>
        </Box>
        <Divider/>
        <Box paddingY={2}>
            <Typography variant="h6" gutterBottom>Customer Provided skiza script</Typography>
            <Typography>{currentRequest.skiza_tune_script || 'N/A'}</Typography>
        </Box>
        </>
    );
}

export default SkizaRequestDetail;
