import { Box, Button, Divider, Modal, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlashMessage from '../components/FlashMessage';
import Pagination from '../components/Pagination';
import SelectInput from '../components/SelectInput';

import { urls } from '../constants';
import { getData, postData } from '../httpUtils';
import { setSkizaCategories, setSkizaTunes } from '../redux/actions';
import CreateSkizaTune from './CreateSkizaTune';
import ListSkizaTuneItem from './ListSkizaTuneItem';

const TunesHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '3vh',
    paddingTop: '2vh'
})

const ListSKizaTunes = () => {
    const dispatch = useDispatch()
    const accessToken = useSelector(state=>state.accessToken)
    const skizaTunes = useSelector(state=>state.skizaTunes)
    const categories = useSelector(state=>state.skizaCategories) || []
    const categoryList = [{id: '', name:'All'}, ...categories.map(cat=>({id: cat.id, name:cat.name}))]

    const [state, setState] = useState({category_id: ''})

    // use for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination] = useState({})
    const handleSetPagination = paginationObj => {
        setPagination({...paginationObj, items: null})
    }

    // Flash alerts
    const [flashOpen, setFlashOpen] = useState(false)
    const closeFlash = () => setFlashOpen(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('success')
    const flash = (message, type='success') =>{
        setAlertMessage(message)
        setAlertType(type)
        setFlashOpen(true)
    }

    // Modals
    const [createTuneModalOpen, setCreateTuneModalOpen] = useState(false)
    const handleCreateTuneModalClose = () => setCreateTuneModalOpen(false)
    const handleCreateTuneModalOpen = () => setCreateTuneModalOpen(true)

    const fetchSkizaTunes = () =>{
        getData(`${urls.listSkizaTunes}?page=${currentPage}&category_id=${state.category_id}`, accessToken).then(resp=>{
            console.log('Fetch tunes: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaTunes(responseData.data.items))
                handleSetPagination(responseData.data)
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    const featureSkizaTune = tuneId =>{
        postData(urls.featureSkizaTune(tuneId), {}, accessToken).then(resp=>{
            console.log('feature tune: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==201){
                flash('skiza tune is now featured')
                // update list
                fetchSkizaTunes()
            }
        }).catch(e=>console.log(e))
    }

    const unFeatureSkizaTune = tuneId =>{
        postData(urls.unFeatureSkizaTune(tuneId), {}, accessToken).then(resp=>{
            console.log('unfeature tune: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==201){
                flash('skiza tune removed from featured')
                // update list
                fetchSkizaTunes()
            }
        }).catch(e=>console.log(e))
    }

    useEffect(() => {
        //fetch skiza categories
        getData(urls.listSkizaCategories, accessToken).then(resp=>{
            console.log('fetch categories: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaCategories(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })

        // fetch skiza tunes
        fetchSkizaTunes()

    }, [currentPage, state]);

    return (
    <>
    <TunesHeader>
        <Typography variant='h5' sx={{
            ml: 1,
            fontWeight: 'bold'
        }}
        >
            Skiza Tunes
        </Typography>
        <Box sx={{width: '30%'}}>
            <SelectInput
                value ={state.category_id}
                id='category_id'
                label='Filter by Category'
                onChange={e=>setState({...state, category_id: e.target.value})}
                options={categoryList}
            />
        </Box>
        <Button variant='contained' onClick={handleCreateTuneModalOpen} sx={{color:'white'}}>
            New Skiza tune
        </Button>
    </TunesHeader>

    <Modal
        open={createTuneModalOpen}
        onClose={handleCreateTuneModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Paper sx={{
            width: '70%',
            p: 3
        }}>
            <CreateSkizaTune closeModal={handleCreateTuneModalClose} fetchSkizaTunes={fetchSkizaTunes} flash={flash}/>
        </Paper>
    </Modal>

    {flashOpen && <FlashMessage closeFlash={closeFlash} message={alertMessage} type={alertType}/>}

    <Divider/>
    <TableContainer component={Box}>
        <Table aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Is Featured</TableCell>
                <TableCell>Audio</TableCell>
                <TableCell># Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {skizaTunes && skizaTunes.length? 
            <>
            {skizaTunes.map((tune) => (
                <ListSkizaTuneItem
                    key={tune.id}
                    tune={tune}
                    fetchSkizaTunes={fetchSkizaTunes}
                    featureSkizaTune={featureSkizaTune}
                    unFeatureSkizaTune={unFeatureSkizaTune}
                    flash={flash}
                />
            ))}
            </>:
            <TableRow>
                <TableCell align='center' colSpan={9}>No tunes yet</TableCell>
            </TableRow>
            }

        </TableBody>
        </Table>
    </TableContainer>

    <Pagination pagination={pagination} setCurrentPage={setCurrentPage}/>
    </>
  );
}

export default ListSKizaTunes;
