import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";

import { urls } from "../constants";
import { postData } from "../httpUtils";
import SelectInput from "./SelectInput";
import TextArea from "./TextArea";
import TextInput from "./TextInput";


const RequestCustomSkizaForm = ({closeModal, flash}) => {
    const [state, setState] = useState({
        customer_email: '',
        customer_phone: '',
        customer_name: '',
        business_name: '',
        business_location: '',
        business_description: '',
        skiza_tune_script: '',
        preferred_voice: '',
    })

    const voicesList = [{id: 'Female', name: 'Female'}, {id: 'Male', name: 'Male'}]

    const [error, setError] = useState(null)
    const [errorFields, setErrorFields] = useState()

    const handleFormSubmit = e =>{
        e.preventDefault()
        postData(urls.listTuneRequests, state).then(resp=>{
            console.log(resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==400){
                setErrorFields(responseData.data.error_fields)
            } else if(responseData.response_code==201){
                closeModal()
                flash('Skiza request submitted')
            } else{
                flash('Could not sent your request', 'error')
            }
        }).catch(e=>{
            console.log(e)
            flash('Could not sent your request', 'error')
        })
    }
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleFormSubmit}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
                Request Custom Skiza Tune @ Kshs 1500/= only
            </Typography>

            {error && <Typography color='error' textAlign='center'>{error}</Typography>}

            <Grid container>

                <Grid item xs={12} md={6} paddingX={1}>
                    <TextInput
                        value={state.customer_name}
                        label="Your Name"
                        id='customer_name'
                        onChange={(e)=>setState({...state, customer_name: e.target.value})}
                        errorFields={errorFields}
                    />
                    <TextInput
                        value={state.customer_email}
                        label="Your Email"
                        id='customer_email'
                        onChange={(e)=>setState({...state, customer_email: e.target.value})}
                        errorFields={errorFields}
                        type='email'
                    />
                    <TextInput
                        value={state.customer_phone}
                        label="Phone Number"
                        id='customer_phone'
                        onChange={(e)=>setState({...state, customer_phone: e.target.value})}
                        errorFields={errorFields}
                    />

                </Grid>
                <Grid item xs={12} md={6} paddingX={1}>
                    <TextInput
                        value={state.business_name}
                        label="Business Name"
                        id='business_name'
                        onChange={(e)=>setState({...state, business_name: e.target.value})}
                        errorFields={errorFields}
                    />
                    <TextInput
                        value={state.business_location}
                        label="Location"
                        id='business_location'
                        onChange={(e)=>setState({...state, business_location: e.target.value})}
                        errorFields={errorFields}
                    />
                    <SelectInput
                        value ={state.preferred_voice}
                        id='preferred_voice'
                        label='Preferred voice'
                        onChange={e=>setState({...state, preferred_voice: e.target.value})}
                        errorFields={errorFields}
                        options={voicesList}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextArea
                        value={state.business_description}
                        label="Business Description (Briefly, What does your business do?)"
                        id='business_description'
                        onChange={(e)=>setState({...state, business_description: e.target.value})}
                        errorFields={errorFields}
                    />
                    <TextArea
                        value={state.skiza_tune_script}
                        label="Skiza script (If you don't have one leave blank our team will create one for you)"
                        id='skiza_tune_script'
                        onChange={(e)=>setState({...state, skiza_tune_script: e.target.value})}
                        errorFields={errorFields}
                    />
                </Grid>
            </Grid>

            <Button variant="contained" fullWidth type='submit' sx={{mt: 4, mb: 2, color:'white'}}>
                Submit
            </Button>

            <Button variant="contained" fullWidth sx={{
                color:'white',
                mt: 2,
                mb: 2, 
                backgroundColor: 'grey',
                '&:hover': {
                    backgroundColor: 'grey'
                }
            }} onClick={closeModal}>
                Cancel
            </Button>

        </Box>
    );
}

export default RequestCustomSkizaForm;
