import { Paper, Typography, Grid } from "@mui/material";
import { urls } from "../constants";
import { CenteredGrid } from "../styledComponents";

const SkizaSampleItem = ({tune}) => {
    return (
        <CenteredGrid item xs={12} sm={6} md={4} >
            <Paper sx={{
                backgroundColor: 'lightGrey.main',
                height: 130,
                width: '90%',
                mt: 4,
                borderTopLeftRadius: 20,
                borderBottomRightRadius: 20,
                px: 1,
                py: 2
            }}>
                <Typography variant="body2" textAlign='center' sx={{fontWeight: 'bold'}} gutterBottom>
                    {tune.name}
                </Typography>

                <Grid container marginTop={3} justifyContent='center'>
                    <CenteredGrid xs={6} item>
                        <audio controls controlsList="nodownload">
                            <source src={urls.audioPath(tune.file_path)} type="audio/mpeg"></source>
                        </audio>
                    </CenteredGrid>
                </Grid>
            </Paper>
        </CenteredGrid>
    );
}
 
export default SkizaSampleItem;