import { Delete, Edit, PlayArrow, Star, StarBorder } from "@mui/icons-material";
import { Modal, Paper, Stack, styled, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import { urls } from "../constants";
import { deleteData } from "../httpUtils";
import EditSkizaTune from "./EditSKizaTune";

const AudioBox = styled('audio')({
    width: 100,
    height: 30
})

const ListSkizaTuneItem = ({tune, unFeatureSkizaTune, featureSkizaTune, fetchSkizaTunes, flash}) => {
    const accessToken = useSelector(state=>state.accessToken)

    const deleteSkizaTune = tuneId =>{
        deleteData(urls.skizaTuneDetail(tuneId), accessToken).then(resp=>{
            console.log('delete tune: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==400){
                flash(responseData.data.error_message, 'error')
            } else if(responseData.response_code==201){
                flash('Skiza tune deleted')
                fetchSkizaTunes()
            } else {
                flash('Something went wrong', 'error')
            }
        }).catch(e=>{
            flash('Something went wrong', 'error')
            console.log(e)
        })
    }

    const [editTuneModalOpen, setEditTuneModalOpen] = useState(false)
    const handleEditTuneModalClose = () => setEditTuneModalOpen(false)
    const handleEditTuneModalOpen = () => setEditTuneModalOpen(true)

    return (
        <>
        <Modal
            open={editTuneModalOpen}
            onClose={handleEditTuneModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Paper sx={{
                width: '70%',
                p: 3
            }}>
                <EditSkizaTune 
                    closeModal={handleEditTuneModalClose} 
                    fetchSkizaTunes={fetchSkizaTunes}
                    flash={flash}
                    tuneId={tune.id}
                />
            </Paper>
        </Modal>
        <TableRow>
            <TableCell component="td" scope="row"> {tune.id} </TableCell>
            <TableCell>{tune.name}</TableCell>
            <TableCell>{tune.code}</TableCell>
            <TableCell>{tune.category_name}</TableCell>
            <TableCell>
                {tune.is_featured?
                    <Star 
                        sx={{color:'green'}}
                        onClick={e=>unFeatureSkizaTune(tune.id)}
                    /> :
                    <StarBorder
                        onClick={e=>featureSkizaTune(tune.id)}
                    />
                }
            </TableCell>

            <TableCell>
                <AudioBox controls controlsList="nodownload">
                    <source src={urls.audioPath(tune.file_path)} type="audio/mpeg"></source>
                </AudioBox>
            </TableCell>

            <TableCell sx={{

            }}>
                <Stack direction='row' gap={2}>
                    <Edit
                        onClick={handleEditTuneModalOpen}
                    />
                    <Delete
                        onClick={(e)=>deleteSkizaTune(tune.id)}
                    />
                </Stack>

            </TableCell>
        </TableRow>

        </>
    );
}
 
export default ListSkizaTuneItem;
