import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { urls } from "../constants";
import { postData } from "../httpUtils";
import { setCurrentUser } from "../redux/actions";

import TextInput from "./TextInput";

const LinkItem = styled(Link)({
    color: 'black'
})

const Signup = ({setShowLogin, closeModal}) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        full_name: '',
        email: '',
        phone: '',
        password: '',
        repeatPassword: ''
    })
    const [error, setError] = useState()
    const [errorFields, setErrorFields] = useState()

    const handleSignup = e => {
        e.preventDefault()
        if(!(state.password && state.password == state.repeatPassword)){
            setError('Provide matching passwords')
        } else{
            postData(urls.signup, state).then(resp=>{
                console.log(resp.status)
                return resp.json()
            }).then(data=>{
                if(data.response_code ==400){
                    setErrorFields(data.data.error_fields)
                } else if (data.response_code == 201){
                    dispatch(setCurrentUser(data.data.user))
                    closeModal()
                }
            }).catch(e=>{
                setError("Could't send your request")
                console.log(e)
            })
        }
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSignup}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
                Signup Here
            </Typography>

            {error && <Typography color='error' textAlign='center'>{error}</Typography>}
            <Grid container>
                <Grid item xs={12} md={6} paddingX={1}>
                    <TextInput
                        value={state.full_name}
                        label="Your Name"
                        id='full_name'
                        onChange={(e)=>setState({...state, full_name: e.target.value})}
                        errorFields={errorFields}
                    />
                    <TextInput
                        value={state.email}
                        label="Email"
                        id='email'
                        onChange={(e)=>setState({...state, email: e.target.value})}
                        errorFields={errorFields}
                        type='email'
                    />
                    <TextInput
                        value={state.phone}
                        label="Phone"
                        id='phone'
                        onChange={(e)=>setState({...state, phone: e.target.value})}
                        errorFields={errorFields}
                    />
                </Grid>
                <Grid item xs={12} md={6} paddingX={1}>
                    <TextInput
                        value={state.password}
                        label="Password"
                        id='password'
                        onChange={(e)=>setState({...state, password: e.target.value})}
                        errorFields={errorFields}
                        type='password'
                    />
                    <TextInput
                        value={state.repeatPassword}
                        label="Repeat Password"
                        id='repeat-password'
                        onChange={(e)=>setState({...state, repeatPassword: e.target.value})}
                        errorFields={errorFields}
                        type='password'
                    />
                </Grid>
            </Grid>
            <Button variant="contained" fullWidth type='submit' sx={{mt: 3, mb: 2, color: 'white'}}>
                Signup
            </Button>
            <LinkItem onClick={()=>setShowLogin(true)}>
                Go to login
            </LinkItem>
        </Box>
    );
}

export default Signup;
