import { Route, Routes } from "react-router-dom";
import { Box, Paper, styled } from "@mui/material";

import AdminNav from "./AdminNav";
import Footer from '../components/Footer'
import ListSkizaTunes from './ListSkizaTunes'
import ListUserAccounts from "./ListUserAccounts";
import ListSkizaRequests from "./ListSkizaRequests";
import SkizaRequestDetail from "./SkizaRequestDetail";

const StyledContentBox = styled(Box)({
    flex: 3,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
})

const ContentBox = () => {
    return (
        <StyledContentBox>
            <AdminNav/>
            <Paper sx={{
                flexGrow: 1,
                p: 2
            }}>
                <Routes>
                    <Route path="" index element={<ListSkizaTunes/>}/>
                    <Route path="/business" element={<ListSkizaTunes/>}/>
                    <Route path="/accounts" element={<ListUserAccounts/>}/>
                    <Route path="/skiza-requests" element={<ListSkizaRequests/>}/>
                    <Route path="/skiza-request-detail" element={<SkizaRequestDetail/>}/>
                </Routes>
            </Paper>
            <Footer/>
        </StyledContentBox>
    );
}

export default ContentBox;
