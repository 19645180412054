import { Typography } from "@mui/material";
import SkizaBiasharaBanner from "./SkizaBiasharaBanner";

const SkizaBiasharaSection = () => {
    return (
        <>
        <Typography variant="h3" textAlign='center' sx={{fontWeight: 'bold'}} marginTop={3} gutterBottom>
            Customize Business Skiza tunes
        </Typography>
        <SkizaBiasharaBanner/>
        </>
    );
}
 
export default SkizaBiasharaSection;
