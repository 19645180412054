export const setAccessToken = token => {
    return {
        type: 'SET_ACCESS_TOKEN',
        payload: token
    }
}

export const setCurrentUser = user => {
    return {
        type: 'SET_CURRENT_USER',
        payload: user
    }
}

export const logoutAction = () =>{
    return {
        type: 'LOGOUT'
    }
}

export const setSkizaCategories = categories =>{
    return {
        type: 'SET_SKIZA_CATEGORIES',
        payload: categories
    }
}

export const setSkizaTunes = tunes => {
    return {
        type: 'SET_SKIZA_TUNES',
        payload: tunes
    }
}

export const setUsers = users => {
    return {
        type: 'SET_USERS',
        payload: users
    }
}

export const setSkizaRequests = requests => {
    return {
        type: 'SET_SKIZA_REQUESTS',
        payload: requests
    }
}

export const setCurrentRequestId = requestId => {
    return {
        type: 'SET_CURRENT_REQUEST_ID',
        payload: requestId
    }
}

