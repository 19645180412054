import { Box, Button, Grid, Modal, Paper, styled, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from "react";

import { CenteredGrid } from "../styledComponents";
import { personOnPhone, siteUrls, skizaImg1 } from "../constants";
import RequestCustomSkizaForm from "./RequestCustomSkizaForm";
import FlashMessage from "./FlashMessage";
import { Link } from "react-router-dom";

const BannerGridImg = styled(Grid)(({theme})=>({
    display: 'none',
    backgroundImage: `url(${skizaImg1})`,
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
        display: 'block'
    }
}))

const CallToActionGrid = styled(Grid)(({theme})=>({
    color: theme.palette.darkGrey.main,
    [theme.breakpoints.down('md')]: {
        backgroundImage: `url(${personOnPhone})`,
        backgroundSize: 'cover',
        color: 'white'
    }
}))

const ActionPoint = styled(Typography)(({theme})=>({
    marginTop: '6vh',
    fontFamily: "'Josefin Sans', sans-serif",
}))

const LinkItem = styled(Link)({
    textDecoration: 'none'
})

const SkizaBiasharaBanner = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalClose = () => setModalOpen(false)
    const handleModalOpen = () => setModalOpen(true)

    // Flash alerts
    const [flashOpen, setFlashOpen] = useState(false)
    const closeFlash = () => setFlashOpen(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('success')
    const flash = (message, type='success') =>{
        setAlertMessage(message)
        setAlertType(type)
        setFlashOpen(true)
    }

    const handleExploreSamples = () => {
        const samples = document.getElementById('sample-business-tunes')
        if(samples){
            samples.scrollIntoView()
        }
    }

    return (
        <>
        {flashOpen && <FlashMessage closeFlash={closeFlash} message={alertMessage} type={alertType}/>}
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Paper sx={{
                width: {
                    xs: '90%',
                    sm: '70%'
                },
                p: 3,
                maxHeight: '96vh',
                overflowY: 'auto'
            }}>
                <RequestCustomSkizaForm closeModal={handleModalClose} flash={flash}/>
            </Paper>
        </Modal>

        <Grid container>
            <CallToActionGrid item xs={12} md={6}
                sx={{
                    backgroundColor: 'lightGrey.main'
                }}
            >
                <Box className="call-to-action" sx={{paddingTop: 3}}>
                    <Typography className="call-to-action-header" variant="h2" component='h1' textAlign='center' sx={{
                        fontWeight: 'bold',
                        mx: 1
                    }}>
                        Skiza Biashara gives you <span className="pacifico">power</span>
                    </Typography>

                    <Box ml={4}>
                        <ActionPoint variant="h5">
                            <CheckCircleIcon color="primary"/>
                            Advertise your business using customized tunes
                        </ActionPoint>
                        <ActionPoint variant="h5">
                            <CheckCircleIcon color="primary"/>
                            Brand awareness
                        </ActionPoint>
                        <ActionPoint variant="h5">
                            <CheckCircleIcon color="primary"/>
                            Increase your sales
                        </ActionPoint>
                    </Box>

                    <Box className="call-to-action-btns" sx={{mt:2}}>
                        <Grid container>
                            <CenteredGrid item xs={12} md={6}>
                                <LinkItem to={`${siteUrls.skizaBiasharaPage}#sample-business-tunes`}>
                                    <Button variant="contained" sx={{my: 2, color: 'white'}} onClick={handleExploreSamples}>
                                        Explore Samples
                                    </Button>
                                </LinkItem>
                            </CenteredGrid>
                            <CenteredGrid item xs={12} md={6}>
                                <Button variant="contained" sx={{my: 2, color: 'white'}} onClick={handleModalOpen}>
                                    Request a custom tune
                                </Button>
                            </CenteredGrid>
                        </Grid>
                    </Box>
                </Box>
            </CallToActionGrid>
            <BannerGridImg item md={6} xs={12}>
                <Box className="intro-section"></Box>
            </BannerGridImg>
        </Grid>
        </>
    );
}
 
export default SkizaBiasharaBanner;
