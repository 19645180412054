import { Alert, Snackbar } from "@mui/material";

const FlashMessage = ({message, type, closeFlash}) => {
    if(!type){
        type = 'success'
    }
    return (
        <Snackbar open={true} autoHideDuration={6000} onClose={()=>closeFlash()} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}>
            <Alert severity={type} sx={{ width: '100%' }} variant='filled'>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default FlashMessage;
