import { Typography, Box, Grid, Container, TextField, Button, styled} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { siteUrls, urls } from "../constants";
import { getData } from "../httpUtils";
import { setSkizaTunes } from "../redux/actions";
import { CenteredGrid } from "../styledComponents";

import SkizaItem from "./SkizaItem";

const SearchBtn = styled(Button)(({theme})=>({
    marginTop: '4vh',
    color: 'white',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: 0
    }
}))

const LinkText = styled(Link)({
    textDecoration: 'none'
})

const FeaturedSkiza = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const skizaTunes = useSelector(state=>state.skizaTunes.filter(tune=>tune.is_featured))

    const [searchQuery, setSearchQuery] = useState('')

    const handleSearch = () => {
        navigate(siteUrls.skizaTunesPage+`?search=${searchQuery}`)
    }

    const fetchSkizaTunes = () =>{
        getData(urls.listSkizaTunes+'?is_featured=1').then(resp=>{
            console.log('Fetch tunes: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaTunes(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })
    }

    useEffect(() => {
        fetchSkizaTunes()
    }, []);
    
    return (
        <Box sx={{my: 5, mx: '3%'}}>
            <Typography variant="h3" textAlign='center' sx={{fontWeight: 'bold'}}>
                Skiza Tunes
            </Typography>
            <Typography textAlign='center'>
                Choose a skiza tune and entertain your callers today
            </Typography>
            <Container>
                <Grid container gap={4} justifyContent='center'>
                    <Grid item xs={12} md={6}>
                        <TextField id="outlined-basic" label="Skiza Code or name" variant="filled" fullWidth sx={{
                            mt: 2,
                            mb: 0
                        }} onChange={e=>setSearchQuery(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SearchBtn variant="contained" onClick={handleSearch}>Search tunes</SearchBtn>
                    </Grid>
                </Grid>
            </Container>
            <Grid container justifyContent='space-evenly'>
                {skizaTunes.map(tune=><SkizaItem key={tune.id} tune={tune}/>)}
            </Grid>
            <CenteredGrid>
                <LinkText to={siteUrls.skizaTunesPage}>
                    <Button variant="contained" sx={{mt:4, color: 'white'}}>View more</Button>
                </LinkText>
            </CenteredGrid>
        </Box>
    );
}
 
export default FeaturedSkiza;
