import { TextField } from "@mui/material";

const TextArea = ({id, value, onChange, errorFields, label, type}) => {
    let error = null

    if(!type){
        type = 'text'
    }

    if(errorFields && errorFields[id]){
        error = errorFields[id].join(', ')
    }

    return (
        <TextField
            error={Boolean(error)}
            id={id}
            label={label}
            helperText={error}
            variant="filled"
            fullWidth
            value={value}
            onChange={onChange}
            type={type}
            sx={{mt: 2}}
            multiline
            rows={2}
        />
    );
}

 
export default TextArea;