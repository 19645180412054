import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

const SelectInput = ({id, value, onChange, errorFields, label, options}) => {
    let error

    if(errorFields && errorFields[id]){
        error = errorFields[id].join(', ')
    }

    return (
        <FormControl fullWidth sx={{mt: 2}} variant='filled'>
        {error && <Typography color='error' textAlign='center'>{error}</Typography>}
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id={id}
                value={value}
                label={label}
                onChange={onChange}
            >
                {options.map(entry=><MenuItem value={entry.id} key={entry.id}>{entry.name}</MenuItem>)}
            </Select>
        </FormControl>
    );
}
 
export default SelectInput;
