import { AttachMoney, Language, MusicNote, People, Topic } from "@mui/icons-material";
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, Paper, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { dmaLogo, siteUrls } from "../constants";

const StyledSideMenu = styled(Box)(({theme})=>({
    backgroundColor: 'lightGrey.main',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}))

const UserBox = styled(Box)({
    margin: '1%'
})

const LogoImg = styled('img')({
    maxWidth: '100%',
    height: 'auto'
})

const LinkItem = styled(Link)({
    textDecoration: 'none',
    color: '#757575',
    padding: 4,
    width: '100%'
})

const LinkText = styled(Typography)({
    fontFamily: "'Josefin Sans', sans-serif",
    fontWeight: 'bold',
    fontSize: '1.1em'
})

const SideMenu = () => {
    const currentUser = useSelector(state=>state.currentUser) || {}

    return (
        <StyledSideMenu>
            <Box sx={{position: 'fixed'}}>
            <Paper elevation={3} sx={{
                height: '98vh',
                width: '98%',
            }}>
                <UserBox>
                    <LogoImg src={dmaLogo}/>
                    <Typography variant="h5" textAlign='center' color='darkGrey.main' fontWeight='bold' gutterBottom>
                        {currentUser.full_name}
                    </Typography>
                    <Typography textAlign='center' variant="subtitle2" fontWeight={100} gutterBottom>
                        {currentUser.email}
                    </Typography>
                    <Divider/>
                </UserBox>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <MusicNote/>
                            </ListItemIcon>
                            <LinkItem to={siteUrls.listSkizaTunes}>
                                <LinkText variant="h6">
                                    Skiza tunes
                                </LinkText>
                            </LinkItem>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>

                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <Topic/>
                            </ListItemIcon>
                            <LinkItem to={siteUrls.listSkizaRequests}>
                                <LinkText variant="h6">
                                    Skiza Requests
                                </LinkText>
                            </LinkItem>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>

                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <People/>
                            </ListItemIcon>
                            <LinkItem to={siteUrls.listAccounts}>
                                <LinkText variant="h6">
                                    Accounts
                                </LinkText>
                            </LinkItem>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>

                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AttachMoney/>
                            </ListItemIcon>
                            <LinkItem>
                                <LinkText variant="h6">
                                    Payments
                                </LinkText>
                            </LinkItem>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>

                    <ListItem>
                        <LinkItem to={siteUrls.home} sx={{width: '100%'}}>
                            <Button variant="contained" fullWidth startIcon={<Language/>} sx={{color:'white',}}>
                                Go to website
                            </Button>
                        </LinkItem>
                    </ListItem>
                </List>
            </Paper>
            </Box>
        </StyledSideMenu>
    );
}
 
export default SideMenu;
