import { Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";

import Home from "./pages/Home";
import Dashboard from "./pages/DashBoard";

function App() {
  return (
    <>
    <CssBaseline/>
    <div className="App">
        <Routes>
            <Route path="/*" element={<Home/>}/>
            <Route path="/portal/*" element={<Dashboard/>}/>
        </Routes>
    </div>
    </>
  );
}

export default App;
