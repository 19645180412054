import { AppBar, Button, Container, Divider, Menu, MenuItem, styled, Toolbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";

import { siteUrls } from "../constants";
import { logoutAction } from "../redux/actions";
import { Language, Logout } from "@mui/icons-material";


const MenuLink = styled(Link)({
    textDecoration: 'none',
    fontWeight: 'bold'
})

const AdminNav = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogout = () =>{
        dispatch(logoutAction())
        navigate(siteUrls.home)
    }

    // pop up menu controls
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    return (
        <AppBar position="sticky">
            <Toolbar>
                <Container sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                    <Typography variant="h6" color='white'>
                        Skiza Portal
                    </Typography>
                    <Typography onClick={handleLogout} sx={{
                        display: {
                            xs: 'none',
                            md: 'flex',
                            color:'white',
                        }
                    }}
                    >
                        <Logout/>
                        Logout
                    </Typography>
                    <MenuIcon sx={{
                        display: {
                            xs: 'flex',
                            md: 'none'
                        }}}
                        aria-controls={menuOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? 'true' : undefined}
                        onClick={handleClick}
                    />
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                    }}
                    >
                        <MenuItem onClick={handleMenuClose}>
                            <MenuLink to={siteUrls.listSkizaTunes}>
                                Skiza tunes
                            </MenuLink>
                        </MenuItem>
                        <Divider/>

                        <MenuItem onClick={handleMenuClose}>
                            <MenuLink to={siteUrls.listSkizaRequests}>
                                Skiza Requests
                            </MenuLink>
                        </MenuItem>
                        <Divider/>

                        <MenuItem onClick={handleMenuClose}>
                            <MenuLink to={siteUrls.listAccounts}>
                                Accounts
                            </MenuLink>
                        </MenuItem>
                        <Divider/>

                        <MenuItem onClick={handleMenuClose}>
                            <MenuLink>
                                Payments
                            </MenuLink>
                        </MenuItem>
                        <Divider/>

                        <MenuItem onClick={handleMenuClose}>
                            <MenuLink to={siteUrls.home} sx={{width: '100%'}}>
                                <Button variant="contained" fullWidth startIcon={<Language/>}>
                                    Go to website
                                </Button>
                            </MenuLink>
                        </MenuItem>
                        <Divider/>

                        <MenuItem onClick={handleMenuClose}>
                            <Button 
                                variant="contained" 
                                fullWidth startIcon={<Logout/>} 
                                color='error'
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </MenuItem>
                        <Divider/>
                    </Menu>

                </Container>
            </Toolbar>
        </AppBar>
    );
}
 
export default AdminNav;
