import { Route, Routes } from "react-router-dom";

import Banner from "../components/Banner";
import FeaturedSkiza from "../components/FeaturedSkiza";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import SkizaBiasharaSection from "../components/SkizaBiasharaSection";
import SKizaBiasharaPage from "./SkizaBiasharaPage";
import SkizaTunesPage from "./SkizaTunesPage";


const Home = () => {
    return (
        <>
        <Nav/>
        <Routes>
            <Route index element={
                <>
                <Banner/>
                <FeaturedSkiza/>
                <SkizaBiasharaSection/>
                </>
            }/>
            <Route path="skiza-tunes" element={<SkizaTunesPage/>}/>
            <Route path="skiza-biashara" element={<SKizaBiasharaPage/>}/>
        </Routes>
        <Footer/>
        </>
    );
}
 
export default Home;
