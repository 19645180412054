import {Stack} from '@mui/material'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SideMenu from '../dashboardComponents/SideMenu';
import ContentBox from '../dashboardComponents/ContentBox'
import { siteUrls } from '../constants';


const Dashboard = () => {
    const currentUser = useSelector(state=>state.currentUser)
    const navigate = useNavigate()

    useEffect(() => {
        if(!(currentUser && (currentUser.is_admin || currentUser.is_super_admin))){
            navigate(siteUrls.home)
        }
    }, []);

    return (
        <Stack direction='row' sx={{
           backgroundColor: 'lightGrey.main' 
        }}>
            <SideMenu/>
            <ContentBox/>
        </Stack>
    );
}
 
export default Dashboard;
