import { SkipNext, SkipPrevious } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

const Pagination = ({pagination, setCurrentPage}) => {
    return (
        <Box mt={5}>
            <Divider/>
            <Stack direction='row' justifyContent='center' gap={1} mt={2}>
                <Button size='small' startIcon={<SkipPrevious/>} variant='contained'
                    disabled={!pagination.prev_num}
                    onClick={()=>setCurrentPage(pagination.prev_num)}
                >
                    Prev
                </Button>
                <Typography variant='subtitle1'>
                    Page {pagination.page} of {pagination.total_pages}
                </Typography>
                <Button size='small' endIcon={<SkipNext/>} variant='contained'
                    disabled={!pagination.next_num}
                    onClick={()=>setCurrentPage(pagination.next_num)}
                >
                    Next
                </Button>
            </Stack>
        </Box>
    );
}
 
export default Pagination;
