import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, styled, Typography } from "@mui/material";

import TextInput from "./TextInput";
import { urls } from "../constants";
import { postData } from "../httpUtils";
import { setAccessToken, setCurrentUser } from "../redux/actions";
import Signup from "./Signup";

const LinkItem = styled(Link)({
    color: 'black'
})

const Login = ({closeModal}) => {
    const dispatch = useDispatch()

    const [showLogin, setShowLogin] = useState(true)

    const [state, setState] = useState({
        email: '',
        password: ''
    })
    const [error, setError] = useState(null)
    const [errorFields, setErrorFields] = useState(null)

    const handleLogin = e =>{
        e.preventDefault()
        if(state.email && state.password){
            postData(urls.login, state).then(resp=>{
                console.log(resp.status)
                return resp.json()
            }).then(data=>{
                if(data.response_code == 400){
                    setErrorFields(data.data.error_fields)
                } else if(data.response_code == 200){
                    dispatch(setAccessToken(data.data.access_token))
                    dispatch(setCurrentUser(data.data.user))
                    closeModal()
                }
            }).catch(e=>{
                setError('Something went wrong')
                console.log(e)
            })            
        } else{
            setError('Fill in all fields')
        }
    }

    return (
        <>{showLogin?
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleLogin}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
                Enter Credentials
            </Typography>

            {error && <Typography color='error' textAlign='center'>{error}</Typography>}

            <TextInput
                value={state.email}
                label="Email"
                id='email'
                onChange={(e)=>setState({...state, email: e.target.value})}
                errorFields={errorFields}
                type='email'
            />
            <TextInput
                value={state.password}
                label="Password"
                id='password'
                onChange={(e)=>setState({...state, password: e.target.value})}
                errorFields={errorFields}
                type='password'
            />
            <Button variant="contained" fullWidth type='submit' sx={{mt: 3, mb: 2, color: 'white'}}>
                Login
            </Button>
            <LinkItem onClick={()=>setShowLogin(false)}>
                Signup Instead
            </LinkItem>
        </Box> :
         <Signup setShowLogin={setShowLogin} closeModal={closeModal}/>}</>
    );
}
 
export default Login;
