import {Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import { urls } from "../constants";
import { postData } from "../httpUtils";

const CreateSkizaTune = ({closeModal, fetchSkizaTunes, flash}) => {
    const initialState = {
        skiza_price: 1.5,
        owner: 'DMA',
        code: '',
        category_id: '',
        name: ''
    }
    const [state, setState] = useState(initialState)
    const [errorFields, setErrorFields] = useState()
    const [error, setError] = useState(null)

    const [selectedFile, setSelectedFile] = useState()

    const handleFileChange = e => {
        const file = e.target.files[0]
        setSelectedFile(file)
    }

    const accessToken = useSelector(state=>state.accessToken)
    const categories = useSelector(state=>state.skizaCategories)
    const categoryList = categories.map(cat=>({id: cat.id, name:cat.name}))

	const uploadAudioFile = (tuneId) => {
		const formData = new FormData();
        if(!selectedFile){
            flash('No file selected', 'error')
        } else{
            formData.append('file', selectedFile, selectedFile.name);

            fetch(
                urls.editSkizaAudio(tuneId),
                {
                    method: 'PUT',
                    body: formData,
                    headers: {Authorization: `Bearer ${accessToken}`}
                }
            )
                .then((response) => {
                    if(response.status == 201){
                        flash('File upload successful')
                        fetchSkizaTunes()
                    } else if(response.status == 400){
                        flash('Only MP3 and wav format allowed', 'error')
                    } else if(response.status == 413){
                        flash('File needs to be less than 10MB', 'error')
                    } else {
                        flash('Unable to upload audio', 'error')
                    }
                    return response.json()
                })
                .then((result) => {
                    console.log('Success:', result);
                })
                .catch((error) => {
                    flash('Unable to upload audio', 'error')
                    console.error('Error:', error);
                });
        }
	};

    const handleFormSubmit =e =>{
        e.preventDefault()
        postData(urls.listSkizaTunes, state, accessToken).then(resp=>{
            console.log(resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==400){
                setErrorFields(responseData.data.error_fields)
            } else if(responseData.response_code==201){
                flash('Skiza tune Created successfully')
                closeModal()
                uploadAudioFile(responseData.data.id)
                fetchSkizaTunes()
            } else{
                setError('Something went wrong')
            }
        }).catch(e=>{
            console.log(e)
            setError('Something went wrong')
        })
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleFormSubmit}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
                Create New SKiza Tune
            </Typography>

            {error && <Typography color='error' textAlign='center'>{error}</Typography>}

            <Grid container>
                <Grid item xs={12} md={6} paddingX={1}>
                    <TextInput
                        value={state.name}
                        label="Skiza Tune Name"
                        id='name'
                        onChange={(e)=>setState({...state, name: e.target.value})}
                        errorFields={errorFields}
                    />
                    <TextInput
                        value={state.code}
                        label="Code"
                        id='code'
                        onChange={(e)=>setState({...state, code: e.target.value})}
                        errorFields={errorFields}
                    />
                    <TextInput
                        value={state.skiza_price}
                        label="Price"
                        id='skiza_price'
                        onChange={(e)=>setState({...state, skiza_price: e.target.value})}
                        errorFields={errorFields}
                    />
                </Grid>
                <Grid item xs={12} md={6} paddingX={1}>
                    <TextInput
                        value={state.owner}
                        label="Owner"
                        id='owner'
                        onChange={(e)=>setState({...state, owner: e.target.value})}
                        errorFields={errorFields}
                    />
                    <SelectInput
                        value ={state.category_id}
                        id='category_id'
                        label='Category'
                        onChange={e=>setState({...state, category_id: e.target.value})}
                        errorFields={errorFields}
                        options={categoryList}
                    />
                    <Box>
                        <TextInput
                            id='file'
                            onChange={handleFileChange}
                            fullWidth
                            type='file'
                            accept=".mp3"
                        />
                    </Box>
                </Grid>
            </Grid>

            <Button variant="contained" fullWidth type='submit' sx={{mt: 4, mb: 2, color:'white'}}>
                Submit
            </Button>

            <Button variant="contained" fullWidth sx={{
                color:'white',
                mt: 2,
                mb: 2, 
                backgroundColor: 'grey',
                '&:hover': {
                    backgroundColor: 'grey'
                }
            }} onClick={closeModal}>
                Cancel
            </Button>

        </Box>
    );
}
 
export default CreateSkizaTune;
