import { Box, Modal, Paper, Stack, styled, Typography } from "@mui/material"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Accessibility, Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { useState } from "react";

import Login from "./Login";
import { useSelector } from "react-redux";


const SocialLink = styled('a')({
    color: 'white',
    textDecoration: 'none'
})

const Footer = () => {
    const currentUser = useSelector(state=>state.currentUser)

    //modal controls
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalClose = () => setModalOpen(false)
    const handleModalOpen = () => {
        if(!currentUser){
            setModalOpen(true)
        }
    }

    return (
        <>
        <Box bgcolor='darkGreen.main' paddingTop={5} paddingBottom={3}>
            <Typography variant="h6" color='white' textAlign='center' fontWeight={100} gutterBottom>
                <LocationOnIcon/>
                Vision Plaza, 2nd floor Mombasa Road - Nairobi, Kenya
            </Typography>
            <Stack direction='row' justifyContent='center' color='white' gap={3}>
                <SocialLink href="https://twitter.com/DigitalMedia_Ag" target='_blank'>
                    <Twitter/>
                </SocialLink>

                <SocialLink href="https://www.facebook.com/digitalaggregators" target='_blank'>
                    <Facebook/>
                </SocialLink>

                <SocialLink href="https://www.instagram.com/digital_aggregators" target='_blank'>
                    <Instagram/>
                </SocialLink>

                <SocialLink href="https://www.linkedin.com/company/digital-media-aggregators" target='_blank'>
                    <LinkedIn/>
                </SocialLink>

                <span onClick={e=>handleModalOpen()}>
                    <Accessibility/>
                </span>

            </Stack>
            <Typography textAlign='center' mt={4} fontWeight={400}>
                <Typography color='white'  variant='overlay'>
                    &copy; {new Date().getFullYear()} Digital Media Aggregators
                </Typography>
            </Typography>
        </Box>

        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Paper sx={{
                width: {
                    xs: '90%',
                    sm: '70%'
                },
                p: 3
            }}>
                <Login closeModal={handleModalClose}/>
            </Paper>
        </Modal>
        </>
    );
}

export default Footer;