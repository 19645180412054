import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';

import './index.css'
import App from './App';
import store from './redux/store';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00c853',
            light: '#b9f6ca '
        },
        lightGrey: {
            main: '#f5f5f5'
        },
        darkGrey: {
            main: '#424242'
        },
        darkGreen: {
            main: '#1b5e20'
        }
    }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
