export const skizaRequests = [
    {
        id: 1,
        customer_name: 'Customer 1',
        business_name: 'Business x',
        business_location: 'NRB',
        business_description: 'Software Development',
        skiza_tune_script: 'Welcome to development house. Stay on the call and learn more',
        contact_email: 'business@email.com',
        contact_phone: '0724452457214',
        preferred_voice: 'Female',
        is_processed: true,
        payment_status: 'Pending'
    },
]

export const payments = [
    {
        id: 1,
        tx_time: '20/10/2022:17:16',
        mpesa_id: 'NJDHH5946',
        phone: '002156861',
        amount: '1500',
        status: 'Success',
        tune_request_id: 45
    },
]
