import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FlashMessage from "../components/FlashMessage";
import RequestCustomSkizaForm from "../components/RequestCustomSkizaForm";
import SkizaBiasharaBanner from "../components/SkizaBiasharaBanner";
import SkizaSampleItem from "../components/SkizaSampleItem";
import { urls } from "../constants";
import { getData } from "../httpUtils";
import { setSkizaCategories, setSkizaTunes } from "../redux/actions";
import { CenteredGrid } from "../styledComponents";

const SKizaBiasharaPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    const categories = useSelector(state=>state.skizaCategories)
    const bsCategory = categories.find(cat=>cat.name=='Sample Business Tunes')
    const categoryId = bsCategory? bsCategory.id: ''
    const skizaTunes = useSelector(state=>state.skizaTunes.filter(tune=>tune.category_id== categoryId))

    useEffect(() => {
        if(location.hash =='#sample-business-tunes'){
            const samples = document.getElementById('sample-business-tunes')
            samples.scrollIntoView()
        }

        // Fetch categories
        getData(urls.listSkizaCategories).then(resp=>{
            console.log('fetch categories: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaCategories(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })

        // fetch samples
        getData(`${urls.listSkizaTunes}?category_id=${categoryId}`).then(resp=>{
            console.log('Fetch Samples tunes: ', resp.status)
            return resp.json()
        }).then(responseData=>{
            if(responseData.response_code==200){
                dispatch(setSkizaTunes(responseData.data.items))
            }
        }).catch(e=>{
            console.log(e)
        })


    }, []);

    const [modalOpen, setModalOpen] = useState(false)
    const handleModalClose = () => setModalOpen(false)
    const handleModalOpen = () => setModalOpen(true)

    // Flash alerts
    const [flashOpen, setFlashOpen] = useState(false)
    const closeFlash = () => setFlashOpen(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('success')
    const flash = (message, type='success') =>{
        setAlertMessage(message)
        setAlertType(type)
        setFlashOpen(true)
    }

    return (
        <Box>
            <SkizaBiasharaBanner/>
            <div id='sample-business-tunes'>
                <Typography textAlign='center' variant="h4" sx={{
                fontWeight: 'bold',
                mt: 4
                }}>
                    Sample business tunes
                </Typography>
            </div>

            <CenteredGrid container>
                {skizaTunes.map(tune=><SkizaSampleItem key={tune.id} tune={tune}/>)}
            </CenteredGrid>
            <Box my={6}>
                <CenteredGrid>
                    <Button variant="contained" onClick={handleModalOpen} sx={{color:'white'}}>
                        Get your own custom skiza tune today
                    </Button>
                </CenteredGrid>
            </Box>

            {flashOpen && <FlashMessage closeFlash={closeFlash} message={alertMessage} type={alertType}/>}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                <Paper sx={{
                width: {
                    xs: '90%',
                    sm: '70%'
                },
                    p: 3,
                    maxHeight: '96vh',
                    overflowY: 'auto'
                }}>
                    <RequestCustomSkizaForm closeModal={handleModalClose} flash={flash}/>
                </Paper>
            </Modal>

        </Box>
    );
}
 
export default SKizaBiasharaPage;